.content {
  display: flex;
  flex-direction: column; /* Stacking for mobile */
  align-items: center; /* Centering content */
  text-align: center; /* Center text */
}

.textBox {
  margin-bottom: 20px;
}

.imgBox {
  margin-bottom: 20px;
}

.thumb {
  display: flex; /* Flexbox for thumbnails */
  justify-content: center;
}

.thumb li {
  margin: 0 5px; /* Margin for spacing */
}

.sci {
  display: flex;
  justify-content: center;
  list-style: none; /* No bullets for social icons */
  padding: 0;
}

.sci li {
  margin: 0 10px; /* Margin for spacing */
}

.news-app-promo {
  box-sizing: border-box;
  background-color: #000;
  padding: 0.5em;
  margin-top: 1em;
}

.news-app-promo__section {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 8px;
}

.news-app-promo-text {
  color: #fff;
  font-family: helvetica;
  min-width: 277px;
  border-right: 0.25em solid #fff;
  border-left: 0.25em solid #fff;
  padding: 0 1em;
  width: 35%;
  margin: 1em auto;
  display: block;
}

.news-app-promo-text__tagline {
  font-size: 1.09em;
}

.news-app-promo-text__download {
  font-size: 2.25em;
  font-weight: 600;
}

.news-app-promo-buttons {
  margin: 0 auto;
  max-width: 35%;
  display: block;
}

.news-app-promo-buttons__buttons {
  display: block;
}

.news-app-promo-buttons__logo {
  display: inline-block;
}

.news-app-promo-subsection {
  display: inline-block;
  margin: 0 auto;
  margin-right: 10px;
}

.news-app-promo__bbc-logo {
  display: inline-block;
  width: 106px;
  margin-bottom: 8px;
}

.news-app-promo__play-store,
.news-app-promo__app-store {
  display: block;
  width: 161px;
  height: auto;
  margin-bottom: 8px;
}

.news-app-promo-subsection--link {
  text-decoration: none;
  border: 0;
}